import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import Sidebar from './components/Sidebar';
import MainContent from './components/MainContent';
import './index.css';

const Background = styled.div`
    width: 100%;
    height: 100vh;
    background-color: #0f172a;
    background-image: ${(props) => `radial-gradient(
        circle 500px at ${props.mouseX} ${props.mouseY},
        rgba(40, 40, 240, 0.12) 0%,
        rgba(50, 50, 200, 0) 100%
    )`};
    ::selection {
        background-color: rgb(94, 234, 212);
        color: rgb(21, 78, 95);
    }
`;

const Layout = styled.div`
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;
    margin: 0 auto; /* Centers the layout horizontally */
    box-sizing: border-box; /* Includes padding and border in the element's height */
    overflow-y: auto;
    font-family: 'Roboto', Arial, sans-serif; // Use Roboto, with Arial as a fallback

    @media (max-width: 1000px) {
        flex-direction: column; // Stack vertically on smaller screens
    }
`;

function App() {
    const [mousePosition, setMousePosition] = useState({ x: '50%', y: '50%' }); // Default to center
    const handleMouseMove = (event) => {
        setMousePosition({ x: `${event.clientX}px`, y: `${event.clientY}px` });
    };
    const layoutRef = useRef(null);
    const layoutId = "layoutContainer";  // Define an ID for the layout container

    return (
        <Background
            onMouseMove={handleMouseMove}
            mouseX={mousePosition.x}
            mouseY={mousePosition.y}
        >
            <Layout id={layoutId} ref={layoutRef}>
                <Sidebar containerId={layoutId} scrollTo={layoutRef} />
                <MainContent />
            </Layout>
        </Background>
    );
}

export default App;
