import React from 'react';
import styled from 'styled-components';

const ExperienceCard = styled.div`
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 20px;
    margin-bottom: 20px;
    padding: 20px;
    background-color: transparent; // Default background is transparent
    color: #cbd5e1;
    border-radius: 8px;
    box-sizing: border-box;
    max-width: 40rem;
    transition: background-color 0.2s; // Smooth transition for background color change
    z-index: 2;  // fixes hovering over the cards not highlighting when it's near the bottom (1/2)
    pointer-events: auto; // fixes hovering over the cards not highlighting when it's near the bottom (2/2)


    &:hover {
        background-color: rgba(150, 150, 255, 0.05); // Background color on hover
    }

    .date {
        padding-top: 5px;
        grid-column: span 2; // Occupies 2 of the 8 columns
        font-weight: bold;
        font-size: 0.8rem;
        align-self: center; // Aligns vertically in the center of its grid area
        color: rgba(148, 163, 184); /* Specify color */
    }

    .titleAndDescription {
        grid-column: 3 / 9; // Starts right after the date and spans to the end
        width: 16
    }

    .title {
        font-size: 1.25rem;
        font-weight: bold; // Makes the font bold
        color: #e2e8f0; // A lighter shade of white for contrast
        align-self: center; // Aligns vertically in the center of its grid area
        line-height: 2rem;
        padding-left: 4px;
    }

    .description {
        overflow-wrap: break-word; // Allows long words to be broken and wrap onto the next line
        word-wrap: break-word; // Legacy support for wrapping
        overflow: hidden; // Hides content that overflows the box
        text-overflow: ellipsis; // Adds ellipsis if the text overflows the content box
        max-width: 100%; // Ensures the description does not exceed its container
        color: rgba(148, 163, 184); /* Specify color */
        padding-left: 4px;
    }

    .tags {
        grid-column: span 8; // Aligns under the description
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }

    .tag {
        background-color: #2d3748;
        padding: 5px 10px;
        border-radius: 5px;
    }
`;

const Image = styled.img`
    width: 150px; // Set width including the border
    height: 100px; // Set height including the border
    object-fit: cover; // Ensures the image covers the assigned area
    border-radius: 3px; // Rounded corners
    border: 2px solid rgba(148, 163, 184); // White border
    margin-top: 14px;


    @media (max-width: 1500px) {
        width: 130px; // Set width including the border
    }
    @media (max-width: 1200px) {
        width: 100px; // Set width including the border
    }
    @media (max-width: 1000px) {
        display: none;
    }
    
`;

const ExperienceEntry = ({date, title, description, tags, imageSrc, url}) => (
    <ExperienceCard>
            <div className="date">{date}
                {imageSrc && <Image src={imageSrc} alt={title} />}
            </div>
            <div className="titleAndDescription">
                {url ? (<a className="title" href={url}>{title}</a>) : (<div className="title" >{title}</div>)}
                <div className="description">{description}</div>
            </div>
            <div className="tags">
                {tags.map(tag => (
                    <span className="tag" key={tag}>{tag}</span> // Ensure unique key if possible
                ))}
            </div>
        
    </ExperienceCard>
);

export default ExperienceEntry;