import React from 'react';
import styled from 'styled-components';
import { Link, Events } from 'react-scroll'; // Import Link from react-scroll

const StyledSidebar = styled.div`
    width: 25rem; /* Fixed width for the sidebar */
    height: 100vh; /* Full viewport height */
    color: white;
    display: flex;
    flex-direction: column;
    padding: 6rem;
    position: fixed;
    pointer-events: none; // Disables pointer events for the sidebar
    z-index: 10;
    right: 50vw;

    .nav-link {
        font-size: 0.75rem;
        font-weight: 900;
        color: rgba(148, 163, 184);
        background: none;
        border: none;
        cursor: pointer;
        position: relative;
        padding-left: 40px; /* Space for the line */
        margin-bottom: 20px; /* Space between links */
        transition: padding-left 0.2s ease; /* Updated to animate padding-left */
        pointer-events: auto;

        &::before {
            width: 25px; /* Initial width of the line */
            content: '';
            position: absolute;
            left: 0;
            top: 50%; /* Center the line vertically relative to the text */
            transform: translateY(
                -50%
            ); /* Align the line in the middle of the text */
            height: 1px; /* Height of the line */
            background-color: rgba(148, 163, 184);
            transition: width 0.2s ease; /* Animate width change */
        }

        &:hover {
            padding-left: 70px; /* Increase padding on hover to push the text to the right */
            color: #e2e8f0;
        }

        &:hover::before {
            width: 55px; /* Double the width on hover */
            background-color: #e2e8f0;
        }
    }

    .content {
        display: flex; // Ensure the content is styled as a flex container
        flex-direction: column; // Arrange children (text and links) in a column
        flex-grow: 1; /* Allow this container to expand */
        overflow-y: auto; /* Additional scrolling if content is too long */
        width: 100%; /* Full width within the sidebar */
        text-align: left; /* Align text to the left */
        margin-bottom: 2rem; /* Space before the footer */
    }

    .name {
        font-size: 3rem;
        font-weight: bold;
        color: #e2e8f0;
        margin-top: 0; /* Remove top margin to reduce space usage */
        margin-bottom: 0; /* Remove top margin to reduce space usage */
    }

    .jobTitle {
        font-size: 1.25rem;
        font-weight: bold;
        color: #e2e8f0;
        line-height: 2rem;
        margin-top: 0.2rem;
        margin-bottom: 0.5rem;
    }

    .summary {
        max-width: 25rem;
        color: rgba(148, 163, 184);
        padding-top: 0rem;
        line-height: 1.3rem;
        margin-bottom: 3rem;
    }

    .footer {
        color: rgba(108, 123, 144);
        padding-top: 2.5rem;
        padding-bottom: 10rem; /* Ensure padding at the bottom */
        max-width: 30rem;
    }

    .name,
    .jobTitle,
    .summary {
        pointer-events: auto; // Specifically allow text selection on these text elements
    }

    .summaryContent {
        width: 18rem;
    }

    

    @media (max-width: 1000px) {
        box-sizing: border-box;
        position: relative;
        flex-grow: 1;
        margin: 0px;
        width: 100%; // Take full width of the screen
        height: 100vh; // Adjust height to content
        padding: 4rem; // Reduce padding on smaller screens
        order: -1; // Place sidebar above MainContent
        right: 0;
        min-height: 100vh;
        
        .content {
            overflow-y: visible;
        }

        .footer {
            top: 0px;
            padding: 0px;
            margin: 0px;
        }
    }

    @media (max-width: 600px) {
        padding: 2rem 2rem 7rem 2rem;
    }
`;

const Sidebar = ({ scrollTo }) => {
    // Set up event listeners for react-scroll
    React.useEffect(() => {
        Events.scrollEvent.register('begin', function () {
            console.log('begin', arguments);
        });

        Events.scrollEvent.register('end', function () {
            console.log('end', arguments);
        });

        return () => {
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        };
    }, []);

    return (
        <StyledSidebar>
            <div className="content">
                <p className="name">Marko Popovic</p>
                <p className="jobTitle">Senior Software Engineer</p>
                <div className="summaryContent">
                    <p className="summary">
                        Harnessing the power of modern web technologies to
                        create robust, scalable web solutions.
                    </p>
                </div>
                {/* Navigation links */}
                <Link
                    className="nav-link"
                    to="about"
                    containerId={
                        scrollTo.current ? scrollTo.current.id : undefined
                    }
                    smooth={true}
                    duration={500}
                >
                    ABOUT
                </Link>
                <Link
                    className="nav-link"
                    to="experience"
                    containerId={
                        scrollTo.current ? scrollTo.current.id : undefined
                    }
                    smooth={true}
                    duration={500}
                >
                    EXPERIENCE
                </Link>
                <Link
                    className="nav-link"
                    to="education"
                    containerId={
                        scrollTo.current ? scrollTo.current.id : undefined
                    }
                    smooth={true}
                    duration={500}
                >
                    EDUCATION
                </Link>
            </div>
            <p className="footer">
                Crafted quickly from scratch as a personal challenge, this
                site's design was inspired by simple portfolios and built using
                React.
            </p>
        </StyledSidebar>
    );
};

export default Sidebar;
