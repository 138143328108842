import React from 'react';
import styled from 'styled-components';
import ExperienceEntry from './ExperienceEntry';
import { Element } from 'react-scroll'; // Import Element from react-scroll

const Content = styled.div`
    flex-grow: 1;
    height: 100%;
    color: white;
    padding: 50px;
    box-sizing: border-box;
    width: 100%;
    padding-left: 50%; // Maintain this for layout purposes
    position: relative;
    pointer-events: none; // Add this to allow clicks to pass through when over the sidebar
    z-index: 1;

    &:hover {
        pointer-events: auto; // Enable pointer events when hovered (to allow scrolling)
    }

    .about {
        color: rgba(148, 163, 184);
        padding-top: 1.5rem;
        padding-left: 1rem;
        max-width: 35rem;
        margin-bottom: 5rem;
        line-height: 1.5rem;
        pointer-events: auto; // Ensure text and other interactables inside are always interactable
    }

    @media (max-width: 1000px) {
        position: relative;
        padding-left: 0; // Maintain this for layout purposes
        flex-direction: row;
        flex-grow: unset;
        display: block;
        padding: 3rem; // Adjust padding for smaller screens
        width: 100%; // Ensure it takes the full width available

        .about {
            max-width: 100%;
        }
    }

    @media (max-width: 1000px) {
        padding: 2rem; // Adjust padding for smaller screens
    }

    @media (max-width: 600px) {
        padding: 0.5rem;

        .title {
            line-height: 1.5rem;
        }
    }

    @media (max-height: 600px) {
        padding-top: 10.5rem;
    }
`;

const MainContent = () => (
    <Content>
        {/* Your scrollable content goes here */}
        <Element name="about">
            <p id="about" className="about">
                My fascination with coding began in early high school, initially
                sparked by my interest in problem-solving. This early exposure
                laid the foundation for my career, driving me to explore more
                deeply the intricacies of software development.
                <p />
                Over the past eight years, my professional journey has been
                concentrated at a few pivotal organizations, notably at
                Formitize. At Formitize, I began working extensively with PHP
                and JavaScript, which equipped me with a solid foundation in
                both front-end and back-end technologies. As I advanced, I
                transitioned to using Node.js and React, driven by a desire to
                create more dynamic and responsive applications. My role at
                Quantify further deepened this path, where I led the development
                of the entire platform.
                <p />
                In addition to my professional work, I have pursued several
                hobby projects that have allowed me to experiment with and
                implement cutting-edge technologies. These projects have been
                instrumental in keeping my development skills sharp and my
                portfolio diverse.
            </p>
        </Element>

        <Element name="experience">
            <ExperienceEntry
                date="2014 - 2023"
                title="Formitize"
                description="Here I worked on various significant projects as a senior software
                engineer, using PHP and JavaScript, later transitioning to React and
                Node.js to improve scalability and responsiveness."
                tags={[
                    'JavaScript',
                    'PHP',
                    'React',
                    'Node',
                    'GAE',
                    'REST',
                    'Integration',
                    'Cyber Security',
                    'Full-Calendar / Scheduler',
                    'CRM',
                    'MySQL',
                    'CSS',
                ]}
                imageSrc="/images/pFormitize5.png"
                url="https://www.formitize.com/"
            />

            <ExperienceEntry
                date="2023 - 2024"
                title="Quantify"
                description="Here I spearheaded the development of a web app for the construction industry, built on Node and React."
                tags={[
                    'React',
                    'Node',
                    'AWS',
                    'Stripe Payment System',
                    'Registration',
                    'User Management',
                    'File Storage',
                    'NoSQL',
                    'OCR',
                    'Full Stack',
                ]}
                imageSrc="/images/pQuantify2.png"
                url="https://quantifyproject-ca5a7c89a58f.herokuapp.com/"
            />

            <ExperienceEntry
                date="2012 - 2014"
                title="MLC - National Australia Bank"
                description="Here I was a support analyst and is where I first started programming professionally by creating some simple automation and tools."
                tags={['.NET', 'C#', 'Java', 'Jira', 'GitHub', 'Python', 'QA']}
            />
        </Element>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <Element name="education">
            <ExperienceEntry
                date="2010 - 2013"
                title="UTS"
                description="Bachelor of Science in Enterprise Systems Development, University of Technology Sydney
                (2010 – 2013).
                Diploma, IT, Professional Practice, University of Technology Sydney (2013)
                "
                tags={[
                    'Project Management',
                    'Java',
                    'JEE',
                    'Python',
                    'PL/SQL',
                    'MySQL',
                    'Programming Methodologies',
                    'Data Structures & Algorithms',
                    'C++',
                ]}
            />
        </Element>
    </Content>
);

export default MainContent;
